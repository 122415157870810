<template>
  <div class="home fill-height">
    <div class="text-center" style="padding-top: 10%">
      <img :src="logo" width="200" />
    </div>

    <div class="text-center white--text" style="margin: 10% 0;">
      EXPERIENCE FIRST HAND OUR ATELIER <br> AND DESIGN PROPOSALS.
    </div>

    <v-row style="margin-top: 10%; margin-bottom: 10%;">
      <v-col cols="4" class="text-center">
        <img src="../assets/first-floor.jpg" width="100" height="150" />
        <v-btn block depressed rounded style="font-size: 9px;" to="/first-floor">
          FIRST FLOOR 
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="4" class="text-center">
        <img src="../assets/second-floor.jpg" width="100" height="150" />
        <v-btn block depressed rounded style="font-size: 9px;" to="/second-floor">
          SECOND FLOOR 
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-col>

      <v-col cols="4" class="text-center">
        <img src="../assets/third-floor.jpg" width="100" height="150"/>
        <v-btn block depressed rounded style="font-size: 9px;" to="/third-floor">
          THIRD FLOOR 
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <div class="text-center" style="margin: 10% 0;">
      <img :src="escudo" width="50" />
    </div>
  </div>
</template>

<script>
import Logo from '../assets/logo.svg';
import Escudo from '../assets/escudo.svg';

export default {
  name: 'Home',
  computed: {
    logo() {
      return Logo;
    },
    escudo() {
      return Escudo;
    }
  },
  mounted() {
    document.title = 'LFA | HOF';
  }
}
</script>

<style>
.home {
  background-color: #000;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: space-between;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>